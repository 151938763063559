import {
  BookingEvent,
  ClassAttributes,
  EventAttributes,
} from "../ReportCompiler/classes/BookingEvent.js";
import { createDateFromString } from "../GeneralFunctions/CreateDateFromString.js";
import { addDay } from "../GeneralFunctions/AddDay.js";
import { ReportData } from "../utility-functions.js";
export function readBookingEventLog(json) {
  const belArray = [];
  let payPeriodFound;
  let classesInFile = [];
  let staffInFile = [];

  let bookingEventI = 0;
  let bookingClassNameI = 0;
  let bookingLocationI = 0;
  let bookingInstructorI = 0;
  let bookingDateI = 0;
  let bookingTimeI = 0;
  let bookingCSI = 0;
  let bookingLastNameI = 0;
  let bookingFirstNameI = 0;
  let bookingLoggedByI = 0;
  let bookingSessionLength = 0;
  let bookingLoggedDateTimeI = 0;
  let currentBelPayPeriod = [];
  let correctFileType = false;

  for (let i = 0; i < json.length; i++) {
    if (
      json &&
      json[0] &&
      json[0][0] &&
      json[0][0].includes("No booking events during this time period")
    ) {
      return new ReportData(true, []);
    }
    if (isBookingTitleRow(json[i])) {
      let payPeriodObject = findPayPeriodDates(json[i][0]);
      currentBelPayPeriod = payPeriodObject.useablePayPeriod;
      payPeriodFound = payPeriodObject.payPeriod;
    }
    if (isBookingHeaderRow(json[i])) {
      for (let u = 0; u < json[i].length; u++) {
        if (json[i][u].includes("Booking Event")) {
          bookingEventI = u;
        } else if (json[i][u].includes("Booking Detail")) {
          bookingClassNameI = u;
        } else if (json[i][u].includes("Booking Location")) {
          bookingLocationI = u;
        } else if (json[i][u].includes("Booking With")) {
          bookingInstructorI = u;
        } else if (json[i][u].includes("Booking Date")) {
          bookingDateI = u;
        } else if (json[i][u].includes("Booking Start")) {
          bookingTimeI = u;
        } else if (json[i][u].includes("Current Status")) {
          bookingCSI = u;
        } else if (json[i][u].includes("Last Name")) {
          bookingLastNameI = u;
        } else if (json[i][u].includes("First Name")) {
          bookingFirstNameI = u;
        } else if (json[i][u].includes("Booking Made By")) {
          bookingLoggedByI = u;
        } else if (json[i][u].includes("Session Mins")) {
          bookingSessionLength = u;
        } else if (json[i][u].includes("Log Date")) {
          bookingLoggedDateTimeI = u;
        }
      }
      correctFileType = true;
    } else if (isBookingDataRow(json[i])) {
      try {
        const belDate = createDateFromString(
          json[i][bookingDateI],
          json[i][bookingTimeI]
        );

        let sessionLength = parseInt(json[i][bookingSessionLength]);

        if (sessionLength === 30) {
          json[i][bookingClassNameI] = json[i][bookingClassNameI] + " (30 min)";
        }

        const classAttributes = new ClassAttributes(
          json[i][bookingInstructorI],
          json[i][bookingClassNameI],
          json[i][bookingLocationI],
          belDate,
          sessionLength
        );

        const loggedDateObject = createLoggedDateObject(
          json[i][bookingLoggedDateTimeI]
        );
        const loggedBy = findLoggedByPerson(json[i][bookingLoggedByI]);

        const eventAttributes = new EventAttributes(
          json[i][bookingEventI],
          json[i][bookingCSI],
          json[i][bookingFirstNameI],
          json[i][bookingLastNameI],
          loggedBy,
          loggedDateObject
        );

        if (isValidBookingEvent(json[i], belDate, currentBelPayPeriod)) {
          const bookingEvent = new BookingEvent(
            classAttributes,
            eventAttributes,
            true
          );
          belArray.push(bookingEvent);

          classesInFile.push({
            name: json[i][bookingClassNameI],
          });

          const newStaff = {
            name: json[i][bookingInstructorI],
            type: "class",
            location: json[i][bookingLocationI],
          };
          
          let isDuplicate = staffInFile.some(staff => 
            staff.name === newStaff.name && staff.type === newStaff.type && staff.location === newStaff.location
          );
          
          if (!isDuplicate) {
            staffInFile.push(newStaff);
          }

          // staffInFile.push({
          //   name: json[i][bookingInstructorI],
          //   type: "class",
          //   location: json[i][bookingLocationI],
          // });
        }
      } catch (e) {
        console.warn("BEL", e);
      }
    }
  }

  let reportData = new ReportData(correctFileType, belArray);
  reportData.payPeriod = payPeriodFound;
  reportData.classesInFile = classesInFile;
  reportData.staffInFile = staffInFile;

  return reportData;
}

function findPayPeriodDates(row) {
  const secondStr = row.substring(row.indexOf("\n") + 1, row.length);
  const dates = secondStr.substring(0, secondStr.indexOf("\n"));
  const firstDate = dates.substring(0, dates.indexOf("-") - 1).split("/");
  let fd = new Date(firstDate[2], firstDate[0] - 1, firstDate[1]);
  fd = new Date(fd.getTime() + 86400000);
  const secondDate = dates.substring(dates.indexOf("-") + 2).split("/");
  const sd = new Date(secondDate[2], secondDate[0] - 1, secondDate[1]);
  const sdPlusOne = addDay(sd);
  return { payPeriod: [fd, sd], useablePayPeriod: [fd, sdPlusOne] };
}

function isBookingTitleRow(row) {
  return row[0].includes("Booking Events Log");
}

function isBookingHeaderRow(row) {
  let headerRow = false;
  try {
    headerRow = row[0].includes("Booking Event") || row[1].includes("Log Date");
  } catch (e) {
    console.warn(e);
  }
  return headerRow;
}

function isBookingDataRow(row) {
  return (
    row[0] !== null &&
    row[1] !== null &&
    row[2] !== null &&
    row[0] !== "Booking Event" &&
    !row[0].includes("Club Pilates")
  );
}

function createLoggedDateObject(loggedDateAndTimeString) {
  let loggedDate = "0";
  let loggedTime = "00:00";
  if (loggedDateAndTimeString.length > 0) {
    loggedDate = loggedDateAndTimeString.substring(
      0,
      loggedDateAndTimeString.indexOf(" ")
    );
    loggedDate =
      loggedDate.substring(0, loggedDate.length - 2) +
      "20" +
      loggedDate.substring(loggedDate.length - 2, loggedDate.length);
    loggedTime = loggedDateAndTimeString.substring(
      loggedDateAndTimeString.indexOf(" ") + 1,
      loggedDateAndTimeString.length
    );
  }

  return createDateFromString(loggedDate, loggedTime);
}

function findLoggedByPerson(loggedByString) {
  let loggedBy = loggedByString;
  if (loggedByString.length === 0) {
    loggedBy = "N/A";
  }
  return loggedBy;
}

function isValidBookingEvent(row, belDate, currentPayPeriod) {
  const startDate = currentPayPeriod[0];
  const endDate = currentPayPeriod[1];
  return (
    row[0] !== "New Booking Made" &&
    belDate.getTime() > startDate.getTime() &&
    belDate.getTime() < endDate.getTime()
  );
}
