import Amplify from "aws-amplify";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./Dashboard";
import ContactPage from "./ContactPage";
import HomePage from "./Homepage/HomePage";
// Context Providers
import awsConfig from "../aws-exports";
import Signup from "./Auth/Signup";
import Login from "./Auth/Login";
import PrivateRoute from "./PrivateRoute";
import ForgotPassword from "./ForgotPassword";
import StudioSettings from "../pages/StudioSettings/StudioSettings";
import React from "react";
import Referrals from "../pages/Referrals/Referrals";
import StaffEmailer from "../pages/StaffEmailer/StaffEmailer";
import NetworkStatusIndicator from "./NetworkStatusIndicator/NetworkStatusIndicator";
import { NetworkStatusProvider } from "../contexts/NetworkStatusContext/NetworkStatusContext";
import { SnackBarProvider } from "../contexts/SnackBarContext/SnackBarContext";
import AdminRoute from "./AdminRoute";
import OutputTest from "./Admin/OutputTest";
import PrivacyPolicyPage from "../pages/Legal/PrivacyPolicy.js";
import TermsConditionsPage from "../pages/Legal/TermsConditions.js";
import "../Styles/variables.scss";
import "../Styles/typography.scss";
import { CustomDrawerProvider } from "./CustomDrawer/CustomDrawerContext.js";
import CustomDrawer from "./CustomDrawer/CustomDrawer.js";
import "../Styles/colors.scss";
import BetaSignupPage from "../pages/BetaPrograms/BetaSignupPage.js";
Amplify.configure(awsConfig);
import { createTheme, ThemeProvider } from "@mui/material";
import AdminDashboard from "./Admin/AdminDashboard.js";
import { lightenColor, darkenColor } from "../Styles/style-utility-functions.js";
import ImpersonationLogin from "./Admin/ImpersonateLogin.js";
import { AdminImpersonationBannerProvider } from "../contexts/Admin/AdminImpersonationBannerContext.js";
import AdminNotificationForm from "./Admin/AdminNotificationForm.js";
import NotificationManager from "./Admin/NotificationManager.js";
import AdminUserImpersonation from "./Admin/AdminRoutes/AdminUserImpersonation.js";
import UserMetrics from "./Admin/AdminRoutes/UserMetrics.js";


// Create a theme instance
const primaryMain = "#006bff";
const secondaryMain = "#13C296"
const theme = createTheme({
  palette: {
    primary: {
      main: primaryMain, // Your primary blue color
      light: lightenColor(primaryMain, 10),
    },
    secondary: {
      main: secondaryMain,
      light: lightenColor(secondaryMain, 10),
      "100": lightenColor(secondaryMain, 5),

      dark: darkenColor(secondaryMain, 10),



    },
    // success: {
    //   main: "#15c297",
    // },
    link: {
      main: "#494949", // Your custom link color
    },
  },
});

// deploying main withou8t continuous deployment to backend
function App() {

  return (
    <ThemeProvider theme={theme}>

      <AdminImpersonationBannerProvider>
        <SnackBarProvider>
          <NetworkStatusProvider>
            <NetworkStatusIndicator />
            <CustomDrawerProvider>
              <CustomDrawer />
              <Router>

                <Switch>

                  <Route
                    exact
                    path="/"
                    render={(routeProps) => <HomePage></HomePage>}
                  />

                  <Route path="/impersonation-login" component={ImpersonationLogin} />


                  <Route exact path="/beta/:integration" component={BetaSignupPage} />

                  <Route
                    exact
                    path="/signup"
                    render={(routeProps) => <Signup></Signup>}
                  />
                  <Route
                    exact
                    path="/start/:referralCode/:refererUid/:refereeEmail?"
                    render={(routeProps) => <Signup></Signup>}
                  />
                  <Route
                    exact
                    path="/login"
                    render={(routeProps) => <Login></Login>}
                  />

                  <Route
                    exact
                    path="/contact"
                    render={(routeProps) => <ContactPage></ContactPage>}
                  />
                  <Route
                    exact
                    path="/legal/privacy-policy"
                    render={(routeProps) => (
                      <PrivacyPolicyPage></PrivacyPolicyPage>
                    )}
                  />
                  <Route
                    exact
                    path="/legal/terms-conditions"
                    render={(routeProps) => (
                      <TermsConditionsPage></TermsConditionsPage>
                    )}
                  />
                  <Route
                    exact
                    path="/forgot-password"
                    component={ForgotPassword}
                  />

                  <PrivateRoute exact path="/referrals" component={Referrals} />

                  <AdminRoute
                    exact
                    path="/admin"
                    component={AdminDashboard}
                    drawerContent={<UserMetrics />}
                  />

                  <AdminRoute
                    exact
                    path="/admin/add-notification"
                    component={AdminDashboard}
                    drawerContent={<AdminNotificationForm />}
                  />

                  <AdminRoute
                    exact
                    path="/admin/notifications"
                    component={AdminDashboard}
                    drawerContent={<NotificationManager />}
                  />

                  <AdminRoute
                    exact
                    path="/admin/output-test"
                    component={AdminDashboard}
                    drawerContent={<OutputTest />}
                  />

                  <AdminRoute
                    exact
                    path="/admin/user-impersonation"
                    component={AdminDashboard}
                    drawerContent={<AdminUserImpersonation />}
                  />
                  <AdminRoute
                    exact
                    path="/admin/user-metrics"
                    component={AdminDashboard}
                    drawerContent={<UserMetrics />}
                  />

                  <PrivateRoute exact path="/dashboard" component={Dashboard} />

                  <PrivateRoute
                    exact
                    path="/studio-settings"
                    component={StudioSettings}
                  />
                  <PrivateRoute
                    exact
                    path="/studio-setting/studios"
                    component={StudioSettings}
                  />
                  <PrivateRoute
                    exact
                    path="/studio-setting/class-structure"
                    component={StudioSettings}
                  />

                  <PrivateRoute
                    exact
                    path="/studio-setting/staff"
                    component={StudioSettings}
                  />
                  <PrivateRoute
                    exact
                    path="/studio-setting/time-clock-structure"
                    component={StudioSettings}
                  />
                  <PrivateRoute
                    exact
                    path="/studio-setting/commission-structures"
                    component={StudioSettings}
                  />
                  <PrivateRoute
                    exact
                    path="/studio-setting/output-format"
                    component={StudioSettings}
                  />
                  <PrivateRoute
                    exact
                    path="/studio-setting/exceptions"
                    component={StudioSettings}
                  />
                  <PrivateRoute
                    exact
                    path="/studio-setting/payroll-processor-mapping"
                    component={StudioSettings}
                  />
                  <PrivateRoute
                    exact
                    path="/studio-setting/admin-settings"
                    component={StudioSettings}
                  />

                  <PrivateRoute
                    exact
                    path="/studio-setting/sales-settings"
                    component={StudioSettings}
                  />

                  <PrivateRoute
                    exact
                    path="/staff-emailer"
                    component={StaffEmailer}
                  />
                </Switch>
              </Router>
            </CustomDrawerProvider>
          </NetworkStatusProvider>
        </SnackBarProvider>
      </AdminImpersonationBannerProvider>
    </ThemeProvider>
  );
}

export default App;
